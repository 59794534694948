import {Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild} from '@angular/core';
import {BsDropdownConfig} from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'jz-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  providers: [{provide: BsDropdownConfig, useValue: {isAnimated: true, autoClose: true}}]
})

export class NavComponent implements OnInit, OnDestroy {
  // @Input() categories: Category[];
  @Input() onMobile: boolean;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onTouch = new EventEmitter<boolean>();
  @ViewChild('dropdown') subMenu;
  timeRef;

  navigationTabs: {
    id: number, name: string, display_name: string, icon_img: string,
    second_img: string, color: string, is_active: boolean, description: string
  }[] =
    [
      {
        id: 1,
        name: 'home',
        display_name: 'Home',
        icon_img: 'assets/nav/left-drawer-logos/home-before-hover.png',
        second_img: 'assets/nav/left-drawer-logos/home-after-hover.png',
        color: '#64CC44',
        is_active: true,
        description: 'home page for JCosmos',
      },
      {
        id: 5,
        name: 'bluej',
        display_name: 'Blue J',
        icon_img: 'assets/nav/left-drawer-logos/bluej.png',
        second_img: 'assets/nav/left-drawer-logos/bluej-before-hover.png',
        color: '#54C8E8',
        is_active: true,
        description: 'BlueJ landing page',
      },
      {
        id: 10,
        name: 'corporate-compliance-corner',
        display_name: 'Compliance Corner',
        icon_img: 'assets/nav/left-drawer-logos/compliance-logo.png',
        second_img: 'assets/nav/left-drawer-logos/compliance-after-hover.png',
        color: '#9B46BB',
        is_active: true,
        description: 'something',
      },
      {
        id: 7,
        name: 'did-you-know',
        display_name: 'Did you know',
        icon_img: 'assets/nav/left-drawer-logos/diduknow.png',
        second_img: 'assets/nav/left-drawer-logos/diduknow-before-hover.png',
        color: '#E6258B',
        is_active: true,
        description: 'Awesome tips',
      },
      {
        id: 6,
        name: 'employee-corner',
        display_name: 'Employee Corner',
        icon_img: 'assets/nav/left-drawer-logos/employee-corner.png',
        second_img: 'assets/nav/left-drawer-logos/employee-corner-before-hover.png',
        color: '#54C8E8',
        is_active: true,
        description: 'All the employee perks',
      },
      {
        id: 2,
        name: 'jevent',
        display_name: 'Global Events & Travel',
        icon_img: 'assets/nav/left-drawer-logos/global-events.png',
        second_img: 'assets/nav/left-drawer-logos/global-events-after-hover.png',
        color: '#E6258B',
        is_active: true,
        description: 'Jazwares Global events',
      },
      {
        id: 3,
        name: 'human-resources',
        display_name: 'Human Resources',
        icon_img: 'assets/nav/left-drawer-logos/human-resources.png',
        second_img: 'assets/nav/left-drawer-logos/human-resources-after-hover.png',
        color: '#FFC401',
        is_active: true,
        description: 'Human Resources landing page',
      },
      {
        id: 16,
        name: 'security-operations',
        display_name: 'Security Operations Center',
        icon_img: 'assets/nav/left-drawer-logos/human-resources.png',
        second_img: 'assets/nav/left-drawer-logos/human-resources-after-hover.png',
        color: '#9B46BB',
        is_active: true,
        description: 'Security Operations landing page',
      },
      {
        id: 8,
        name: 'jcares',
        display_name: 'Jazwares Cares',
        icon_img: 'assets/nav/left-drawer-logos/jazwares-cares.png',
        second_img: 'assets/nav/left-drawer-logos/jazwares-cares-before-hover.png',
        color: '#E6258B',
        is_active: true,
        description: 'We take care of you',
      },
      {
        id: 4,
        name: 'jnews',
        display_name: 'Jazwares News',
        icon_img: 'assets/nav/left-drawer-logos/brand-marketing.png',
        second_img: 'assets/nav/left-drawer-logos/jnews-logo.png',
        color: '#64CC44',
        is_active: true,
        description: 'Find all the latest news',
      },
      {
        id: 10,
        name: 'jeducation',
        display_name: 'J Resources',
        icon_img: 'assets/nav/left-drawer-logos/jresources.png',
        second_img: 'assets/nav/left-drawer-logos/jresources-hov.png',
        color: '#E6258B',
        is_active: true,
        description: 'Find all the latest news',
      },
      {
        id: 16,
        name: 'jplanet',
        display_name: 'J Planet',
        icon_img: 'assets/nav/left-drawer-logos/jplanet.png',
        second_img: 'assets/nav/left-drawer-logos/jplanet-before-hover.png',
        color: '#64CC44',
        is_active: true,
        description: 'Find all the latest news',
      },
      {
        id: 9,
        name: 'ask',
        display_name: 'Ask jCosmos',
        icon_img: 'assets/nav/left-drawer-logos/ask-jcosmos.png',
        second_img: 'assets/nav/left-drawer-logos/ask-jcosmos-before-hover.png',
        color: '#9B46BB',
        is_active: true,
        description: 'Ask us anything',
      },
      {
        id: 15,
        name: 'org',
        display_name: 'Org Chart',
        icon_img: 'assets/nav/left-drawer-logos/org-icon.png',
        second_img: 'assets/nav/left-drawer-logos/org-icon-hover.png',
        color: '#54C8E8',
        is_active: true,
        description: 'Ask us anything',
      },
    ];

  hover: boolean;
  categoryName: string;

  customClass = 'customClass';
  isSubMenuOpen = false;
  isSubMenuHovered = false;


  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeRef);
  }

  handleOnTouch() {
    this.onTouch.emit(this.onMobile);
  }


  // work around leaving the button and landing in the
  // ul. This time prevent closing the ul unnecessarly.
  // At the same time closes the ul if let open and
  // not hover over after half second.
  leavingDropDown() {
    this.timeRef = setTimeout(() => {
      if (!this.isSubMenuHovered) {
        this.subMenu.hide();
      }
    }, 500);
  }
}
