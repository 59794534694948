import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { PostDetailComponent } from './post-detail/post-detail.component';
import { JazwaresCaresComponent } from './jazwares-cares/jazwares-cares.component';
import { JeventsComponent } from './jevents/jevents.component';
import { EventDetailComponent } from './jevents/event-detail/event-detail.component';
import { JeducationComponent } from './jeducation/jeducation.component';
import { AskJcosmosComponent } from './ask-jcosmos/ask-jcosmos.component';
import { JfitComponent } from './jfit/jfit.component';
import { JnewsComponent } from './jnews/jnews.component';
import { KeepInTouchComponent } from './keep-in-touch/keep-in-touch.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { DidYouKnowComponent } from './did-you-know/did-you-know.component';
import { MeetTeamComponent } from './meet-team/meet-team.component';
import { BluejRefactorComponent } from './bluej/bluej-refactor/bluej-refactor.component';
import { HrTeamListComponent } from './human-resources/hr-team-list/hr-team-list.component';
import { HappybirthdayComponent } from './happybirthday/happybirthday.component';
import { LearningHubDashboardComponent } from './human-resources/learning-hub/learning-hub-dashboard/learning-hub-dashboard.component';
import { EmployeesComponent } from './employees/employees.component';
import { EmployeeComponent } from './employees/employee/employee.component';
import { DiscountsComponent } from './discounts/discounts.component';
import { HumanResourcesComponent } from './human-resources/human-resources.component';
import { SecurityOperationsComponent } from './security-operations/security-operations.component';
import { JparkComponent } from './jpark/jpark.component';
import { BluejPostsDetail } from './Bluej-Posts-Detail/bluej-posts-detail.component';
import { DiversityComponent } from './diversity/diversity.component';
import { SocialComponent } from './social/social.component';
import { JwellnessComponent } from './jwellness/jwellness.component';
import { JplanetComponent } from './jplanet/jplanet.component';
import { NewOrgChartComponent } from './new-org-chart/new-org-chart.component';
import {ParentContComponent} from './human-resources/learning-hub/learning-hub-dashboard/parent-cont/parent-cont.component';
import {UserProfileComponent} from './user-profile/user-profile.component';

// Resolvers
import { PostResolver } from './core/resolvers/post.resolver';
import { EventDetailResolver } from './core/resolvers/event-detail';
import { CategoriesResolver } from './core/resolvers/categories.resolver';
import { BluejCategoryResolver } from './core/resolvers/bluej-category.resolver';
import { BluejCatBannerResolver } from './core/resolvers/bluej-cat-banner.resolver';
import { EmployeesRecogResolver } from './core/resolvers/employees-recog.resolver';
import { JparkResolver } from './core/resolvers/jpark.resolver';
import { PartnerResolver } from './core/resolvers/partner.resolver';
import { EmployeeRecogDetailResolver } from './core/resolvers/employee-recog-detail.resolver';
import { HumanResourcesCatResolver } from './core/resolvers/human-resources-cat.resolver';
import { TheLearningHub } from './core/resolvers/learning-hub.resolver';

// Guards
import { LoggedInGuard } from './core/guards/logged-in-guard';
import { LogosResolver } from './core/resolvers/logos-resolver';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: TopNavComponent,
    resolve: { categories: CategoriesResolver, logos: LogosResolver },
    canActivateChild: [LoggedInGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        component: HomeComponent,
        resolve: { recognitions: EmployeesRecogResolver },
      },

      // routes within the home page starts here
      {
        path: 'recog-employees',
        component: EmployeesComponent,
        resolve: { recognitions: EmployeesRecogResolver },
      },
      {
        path: 'recog-employees/:id',
        component: EmployeeComponent,
        resolve: { recognition: EmployeeRecogDetailResolver },
      },
      { path: 'birthday', component: HappybirthdayComponent },
      { path: 'social', component: SocialComponent },
      { path: 'global-search', component: GlobalSearchComponent },
      {
        path: 'parking-list',
        component: JparkComponent,
        resolve: { allCars: JparkResolver },
      },
      // routes within the home page starts here

      { path: 'bluej', redirectTo: 'bluej/jtank', pathMatch: 'full' },
      { path: 'bluej/team', component: MeetTeamComponent },
      {
        path: 'bluej/:slug',
        component: BluejPostsDetail,
        resolve: { posts: BluejCategoryResolver, cat: BluejCatBannerResolver },
      },
      { path: 'corporate-compliance-corner', component: ComplianceComponent },
      { path: 'did-you-know', component: DidYouKnowComponent },

      // Employee corner routes below
      {
        path: 'discounts',
        component: DiscountsComponent,
        resolve: { partners: PartnerResolver },
      },
      { path: 'jeducation', component: JeducationComponent },
      { path: 'jfit', component: JfitComponent },
      { path: 'jwellness', component: JwellnessComponent },
      {
        path: 'fairness',
        component: DiversityComponent,
        // resolve: {diversityResources: DiversityResolver}
      },
      { path: 'keep-in-touch', component: KeepInTouchComponent },
      { path: 'jplanet', component: JplanetComponent },
      // Employee corner routes ends here

      {
        path: 'jevent',
        component: JeventsComponent,
        // resolve: {event: EventResolver}
      },

      // human resources routes starts here
      {
        path: 'human-resources',
        component: HumanResourcesComponent,
        resolve: { hrCategories: HumanResourcesCatResolver },
      },
      { path: 'human-resources/hr-team-list', component: HrTeamListComponent },
      {
        path: 'human-resources/learning-hub',
        component: ParentContComponent,
        resolve: { learningHubCategories: TheLearningHub },
      },
      // human resources routes ends here

      //security operations routes start here
      {
        path: 'security-operations',
        component: SecurityOperationsComponent,
        resolve: { hrCategories: HumanResourcesCatResolver },
      },

      { path: 'jcares', component: JazwaresCaresComponent },
      { path: 'jnews', component: JnewsComponent },
      { path: 'ask', component: AskJcosmosComponent },

      // routes for posts starts here
      {
        path: 'jevent/:id',
        component: EventDetailComponent,
        resolve: { eventDetail: EventDetailResolver },
      },
      {
        path: 'posts/:id',
        component: PostDetailComponent,
        resolve: { post: PostResolver },
      },
      { path: 'org', component: NewOrgChartComponent },
      { path: 'new-org-chart', component: NewOrgChartComponent },
      // {path: 'user-profile', component: UserProfileComponent},
      // routes for posts ends here
      { path: '**', pathMatch: 'full', component: HomeComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

// ---------------- hiding routes ----------------
// {
//   path: 'halloween-picture-submission',
//   component: SubmitContentComponent,
// },
// {
//   path: 'bluej/Bluej-Posts-Detail-ALE',
//     component: JTankRulesComponent
// },
// {
//   path: 'bluej/Bluej-Posts-Detail/ideas',
//     component: VoteComponent,
//   resolve: {ideas: IdeasResolver, contest: ContestResolver, voteCount: VoteCountResolver}
// },
// {
//   path: 'bluej/Bluej-Posts-Detail/submission',
//     component: SubmitIdeaComponent,
//   resolve: {contest: ContestResolver}
// },
// {
//   path: 'bluej/Bluej-Posts-Detail/ideas/:id',
//     component: IdeaDetailComponent,
//   resolve: {idea: DetailIdeaResolver, voteCount: VoteCountResolver}
// },
// {
//   path: 'halloween-pictures-vote',
//   component: VoteContentComponent,
//   resolve: {
//     submissions: ContentResolver,
//     photoCategories: SubmissionCategoriesResolver,
//     currentUserVoted: VotedCheckResolver,
//   }
// },
// {
//   path: 'kids_corner',
//   component: KidsCornerComponent
// },
// {
//   path: 'COVID-19',
//   component: CovinewsComponent
// },
// {
//   path: 'helpful-tips',
//   component: HelpfulTipsComponent
// },
// {
//   path: 'kids_corner',
//   component: KidsCornerComponent,
// },
// {
//   path: 'jplusplus',
//   component: JplusplusComponent
// },
